import { gql } from 'urql'
import { ILogoAndAvatarVariants } from '~/core/@types/global'

const QueryTenantShow = gql<{
  tenantShow: {
    id?: number
    name?: string
    logoVariants?: ILogoAndAvatarVariants
    phoneNumber?: string
    companySize?: string
    careerSiteSettings?: Partial<{
      canonical_url: string
      department_visibility: string
      description: { [key: string]: string }
      languages: {
        [key: string]: {
          enable: boolean
          default: boolean
        }
      }
      logo: string
      page_title: string
    }>
    currency?: string
  }
}>`
  query {
    tenantShow {
      id
      name
      logoVariants
      phoneNumber
      companySize
      careerSiteSettings
      language
      currency
    }
  }
`

export default QueryTenantShow
